:root {
  --bg-main: #070513;
  --bg-secondary: #201E2B;
  --bg-secondary-hover: #3E3A38;
  --text-color: #ffffff;
  --tint-color: #F50BEB;
  --secondary-color: #FFDE67;
  --muted-color: rgba(255,255,255,0.6);
  --dark-color: rgba(255,255,255,0.60);
  --border-color: rgba(255,255,255,0.1);

  --success: #5FCC43;
  --danger: #F03C3C;

  --font-regular: 'Poppins', sans-serif;
  --font-medium: 'Poppins', sans-serif;


  --transition: all .3s ease;


}

