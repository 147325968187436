.btn {
  --bs-border-radius: 16px;
  --bs-btn-box-shadow: none;
  --bs-btn-border-width: 0;
  user-select: none;
  color: var(--text-color);
  transition: var(--transition);
  position: relative;
  display: flex!important;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  min-width: 48px;
  //overflow: hidden;
  outline: none;
  border-radius: 16px;
  white-space: nowrap;
  flex-wrap: nowrap;
  padding: 0 32px!important;
  border: none!important;
  &:focus-visible {
    box-shadow: none;
  }

  .spinner {
    bottom: 6px;
  }
  .btn__icon {
    margin-left: -16px;
    margin-right: 20px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}


.btn.btn-primary {
  background-color: var(--tint-color);

  &:hover {
    background-color: #F630EE;
  }

  &:active {
    background-color: var(--tint-color);
  }
}

.btn.btn-secondary {
  background: rgba(255, 255, 255, 0.1) !important;

  &:hover {
    background: rgba(255, 255, 255, 0.2) !important;
  }

  &:active {
    background: rgba(255, 255, 255, 0.05) !important;
  }
}

.btn.disabled, .btn:disabled {
  pointer-events: none;
  background: rgba(255, 255, 255, 0.04) !important;

  .btn-content {
    opacity: 0.2 !important;
  }
}

.btn.loading {
  pointer-events: none;
}


.btn.btn-lg {
  min-height: 64px;
  min-width: 64px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 24px;
  &.btn-outline-secondary, &.btn-outline-third {
    border-radius: 16px;
  }

  .btn__icon svg {
    width: 24px;
    height: 24px;
  }

  .btn__icon {
    position: absolute;
    left: 20px;
    margin: 0;
  }
  .spinner {
    bottom: 12px;
  }
}
