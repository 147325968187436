header {
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 24px 24px 24px 32px;
  height: 104px;
  z-index: 100;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: -24px;
    right: 0;
    -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0, var(--bg-main) 48px, var(--bg-main) 100%);
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0, var(--bg-main) 48px, var(--bg-main) 100%);
    background: rgba(18, 18, 18, 0.08);
    //background: rgba(7, 5, 19, 0.6);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
  }

  .btn {
    min-width: 112px;
  }
}

.header__logo {
  position: relative;
  height: 53px;
}

@media only screen and (max-width: 991px) {
  header {
    height: auto;
    background: #14121F;
    padding: 24px 16px 16px;
    border-radius: 0 0 40px 40px;

    &:before {
      display: none;
    }

    .btn {
      font-size: 14px;
    }
  }
  .header__logo {
    height: 40px;
  }
}

@media only screen and (max-width: 630px) {
  header .change__lang_select {
    display: none;
  }
}
@media only screen and (max-width: 420px) {
  .header__logo {
    height: 32px;
  }
  header .btn {
    min-width: auto;
    padding: 0 16px!important;
  }
}

