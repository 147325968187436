@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  h1 {
    font-size: 32px;
    line-height: 32px;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
  h4 {
    font-size: 16px;
    line-height: 24px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {
}


/* touchscreens */
@media (hover: none) {

}
/* mouse */
@media (hover: hover) {
  /* ... */
}