.auth__page {
  display: flex;
  flex-direction: column;
  flex: 1;
  .header__secondary_center {
    padding: 0 48px;
  }
}
.modal-auth-google-separator {
  height: 1px;
  flex: 1;
  background: var(--border-color);
}
.modal-auth-google {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 24px 0;

  span {
    position: relative;
    z-index: 1;
    padding: 0 8px;
  }

  .btn {
    padding-left: 16px;
  }
}

.auth-page-content {
  max-width: 408px;
  width: 100%;
  margin: 0 auto;
}

.auth-recovery-success {
  position: relative;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  &:after {
    content: '';
    position: absolute;
    top: -20px;
    width: 140px;
    height: 64px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 360px;
    filter: blur(60px);
    pointer-events: none;
  }
}
.auth__header_secondary-top {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .btn {
    padding: 0!important;
    .btn__icon {
      margin: 0;
    }
  }
}

.telegram__qr {
  width: 160px;
  height: 160px;
  margin: 0 auto;
  margin-bottom: 16px;
  img {
    width: 100%;
    height: 100%;
  }
}

.auth__page_telegram {
  p span {
    color: #fff;
  }
}
.auth__page_footer-btns {
  display: flex;
  gap: 16px;
  .btn-content {
    padding-left: 40px;
  }
  .btn:nth-child(2) .btn-content {
    padding-left: 44px;
  }
}