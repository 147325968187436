label.req {
  font-weight: bolder;

  &:before {
    content: '*';
    color: var(--bs-danger);
    margin-right: 3px;
  }
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.form-group:has(.input-icon) .form-control {
  padding-left: 44px;
}
.form-group label {
  //font-family: var(--font-medium);
  color: var(--dark-color);
  margin-bottom: 8px;
  font-size: 14px;
}

.form-control-wrap {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 16px;
  //background-color: var(--bg-secondary);
  &:before {
    content: '';
    opacity: 0;
    transition: var(--transition);
    pointer-events: none;
    position: absolute;
    left: -3px;
    right: -3px;
    top: -3px;
    bottom: -3px;
    background-color: var(--tint-color);
    border-radius: 19px;
  }
  &:has(.form-control:focus ) {
    &:before {
      opacity: 0.2;
    }
  }
}

.input-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 12px;
  z-index: 1;
  pointer-events: none;
}


.form-control {
  width: 100%;
  color: #fff;
  background: var(--bg-main);
  border-radius: 16px;
  min-height: 48px;
  font-size: 14px;
  z-index: 1;
  position: relative;
  padding-left: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);

}

.form-control::placeholder {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.64);
}

.form-control:focus {
  color: #fff;
  outline: 0;
  background: var(--bg-main);
  border: 1px solid var(--tint-color);
  box-shadow: 2px var(--tint-color);
}
.form-control:active {
  color: #fff;
  outline: 0;
  box-shadow: none;
  background: var(--bg-main);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.form-control-password {
  padding-right: 54px;
}

.input-pass-eye {
  cursor: pointer;
  position: absolute;
  right: 16px;
  width: 32px;
  height: 32px;
  z-index: 1;
}


select.form-control {
  padding-right: 32px;
  color: #fff;

  &:has(option[value=""]:not(:checked)) {
    color: #fff !important;
  }
}

.form-control-wrap:has(select) {
  height: 48px;
  &:after {
    content: '';
    transition: var(--transition);
    z-index: 2;
    position: absolute;
    pointer-events: none;
    right: 16px;
    width: 16px;
    height: 16px;
    background: url("../../../../assets/icons/chevron_down.svg") no-repeat right center/ 100%;
  }

  &:before {
    border-radius: 22px;
  }
}

.form-control-wrap:has(select:active):after {
  transform: rotate(180deg);
}

