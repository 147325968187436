.react-responsive-modal-root {
  z-index: 1111;
}

.react-responsive-modal-overlay {
}

.react-responsive-modal-modal {
  //padding: 16px;
  padding: 0;
  background: none;
  box-shadow: none;
  margin: 0;
  max-width: 100%;
}


.react-responsive-modal-container {
  background: rgba(7, 5, 19, 0.2);
  backdrop-filter: blur(40px);
}


.modal__container {
  background-color: var(--bg-secondary);
  position: relative;
  padding: 48px;
  border-radius: 40px;
  align-self: center;
  width: 624px;
  max-width: calc(100% - 32px);
  margin: 0 auto;

  &.big {
    width: 924px;
  }

  &.medium {
    width: 700px;
  }
  .page-title {
    text-align: center;
    margin-bottom: 32px;
  }
}

//@keyframes modal__container_animate {
//  0% {
//    transform: translateY(100%);
//  }
//  100% {
//    transform: translateY(0%);
//  }
//}
.modal__gallery {
  max-width: 100%;
}
.modal-close {
  display: inline-flex;
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 2;
  cursor: pointer;
}

.modal__title {
  margin-bottom: 24px;
  font-family: var(--font-medium);
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;

  h4 {
    font-family: var(--font-medium);
    font-size: 16px;
    line-height: 24px;
  }
}

.modal__footer {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 40px;

  &.sm {
    gap: 16px;
  }

  > * {
    flex: 1;
  }
}
