@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  .modal__bottom {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    overflow: hidden;
    max-height: 100dvh;

    .react-responsive-modal-modal {
      overflow: visible;
      transform: translateY(100%);
    }

    .modal__container {
      max-width: 100%;
      border-radius: 32px 32px 0 0;
      padding-top: 40px;
      flex-direction: column;
      display: flex;
      max-height: 90dvh;
      //animation: modal__container_animate 1s linear;
      transition: var(--transition);

      &:before {
        content: '';
        position: absolute;
        align-self: center;
        top: -8px;
        background: rgba(255, 255, 255, 0.1);
        width: 40px;
        height: 4px;
        border-radius: 8px;
      }
    }

    .modal-close {
      top: 16px;
      right: 16px;
    }

    .modal__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;

      h4 {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .modal-footer {
      margin-bottom: 24px;
    }
  }
  .modal-footer {
    width: 100%;
    margin-top: 32px;
    gap: 16px;
  }

}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {
  .modal-footer {
    flex-direction: column;
    .btn {
      width: 100%;
    }
  }
  .modal__container {
    padding: 32px 24px 24px;
  }

}


/* touchscreens */
@media (hover: none) or (max-width: 991px) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}