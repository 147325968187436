.home__page {
  width: 100%;
  max-width: 1328px;
  margin: 0 auto;
  padding: 0 24px;

  section {
    margin-top: 120px;
  }

  h1 {
    pointer-events: none;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 32px;
  }

  h2 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 24px;
  }

  p {
    text-align: center;
    white-space: pre-wrap;
  }
}

.home__section_top {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100dvh;
  min-height: 800px;
  margin-top: 0 !important;

  .btn {
    margin: 40px auto 0;
  }
}

.home__section_top-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.home__top_bg {
  position: absolute;
  width: 705px;
  height: 647px;
  pointer-events: none;
  animation: home-bg-rotate 40s infinite ease-in;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 212px;
    background: rgba(255, 222, 103, 0.2);
    filter: blur(100px);
    left: 25%;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 212px;
    background: rgba(245, 11, 235, 0.2);
    filter: blur(100px);
    right: 25%;
  }
}


.light {
  position: absolute;
  width: 0px;
  background-color: #fff;
  box-shadow: var(--tint-color) 0px 0px 20px 2px;
  opacity: 0;
  top: 100vh;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.x1 {
  -webkit-animation: floatUp 4s infinite linear;
  -moz-animation: floatUp 4s infinite linear;
  -o-animation: floatUp 4s infinite linear;
  animation: floatUp 4s infinite linear;
  -webkit-transform: scale(1.0);
  -moz-transform: scale(1.0);
  -o-transform: scale(1.0);
  transform: scale(1.0);
}

.x2 {
  -webkit-animation: floatUp 7s infinite linear;
  -moz-animation: floatUp 7s infinite linear;
  -o-animation: floatUp 7s infinite linear;
  animation: floatUp 7s infinite linear;
  -webkit-transform: scale(1.6);
  -moz-transform: scale(1.6);
  -o-transform: scale(1.6);
  transform: scale(1.6);
  left: 15%;
}

.x3 {
  -webkit-animation: floatUp 2.5s infinite linear;
  -moz-animation: floatUp 2.5s infinite linear;
  -o-animation: floatUp 2.5s infinite linear;
  animation: floatUp 2.5s infinite linear;
  -webkit-transform: scale(.5);
  -moz-transform: scale(.5);
  -o-transform: scale(.5);
  transform: scale(.5);
  left: -15%;
}

.x4 {
  -webkit-animation: floatUp 4.5s infinite linear;
  -moz-animation: floatUp 4.5s infinite linear;
  -o-animation: floatUp 4.5s infinite linear;
  animation: floatUp 4.5s infinite linear;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  left: -34%;
}

.x5 {
  -webkit-animation: floatUp 8s infinite linear;
  -moz-animation: floatUp 8s infinite linear;
  -o-animation: floatUp 8s infinite linear;
  animation: floatUp 8s infinite linear;
  -webkit-transform: scale(2.2);
  -moz-transform: scale(2.2);
  -o-transform: scale(2.2);
  transform: scale(2.2);
  left: -57%;
}

.x6 {
  -webkit-animation: floatUp 3s infinite linear;
  -moz-animation: floatUp 3s infinite linear;
  -o-animation: floatUp 3s infinite linear;
  animation: floatUp 3s infinite linear;
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  -o-transform: scale(.8);
  transform: scale(.8);
  left: -81%;
}

.x7 {
  -webkit-animation: floatUp 5.3s infinite linear;
  -moz-animation: floatUp 5.3s infinite linear;
  -o-animation: floatUp 5.3s infinite linear;
  animation: floatUp 5.3s infinite linear;
  -webkit-transform: scale(3.2);
  -moz-transform: scale(3.2);
  -o-transform: scale(3.2);
  transform: scale(3.2);
  left: 37%;
}

.x8 {
  -webkit-animation: floatUp 4.7s infinite linear;
  -moz-animation: floatUp 4.7s infinite linear;
  -o-animation: floatUp 4.7s infinite linear;
  animation: floatUp 4.7s infinite linear;
  -webkit-transform: scale(1.7);
  -moz-transform: scale(1.7);
  -o-transform: scale(1.7);
  transform: scale(1.7);
  left: 62%;
}

.x9 {
  -webkit-animation: floatUp 4.1s infinite linear;
  -moz-animation: floatUp 4.1s infinite linear;
  -o-animation: floatUp 4.1s infinite linear;
  animation: floatUp 4.1s infinite linear;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  left: 85%;
}

button:focus {
  outline: none;
}

@-webkit-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    top: 0vh;
    opacity: .2;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    top: -100vh;
    opacity: 0;
  }
}

@-moz-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    top: 0vh;
    opacity: .2;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    top: -100vh;
    opacity: 0;
  }
}

@-o-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    top: 0vh;
    opacity: .2;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    top: -100vh;
    opacity: 0;
  }
}

@keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    top: 0vh;
    opacity: .2;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    top: -100vh;
    opacity: 0;
  }
}

.home__top_model:hover {
  z-index: 1;
  animation-play-state: paused;
  background-size: 120%;

  img {
    transform: scale(1.2);
  }
}

.home__top_avatar {
  overflow: hidden;

  img {
    transition: var(--transition);
    width: 100%;
    height: 100%;
    object-position: top;
    object-fit: cover;
  }
}

.home__top_model_1 {
  position: absolute;
  left: 0;
  top: -80px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  animation: homeModel1 40s infinite alternate linear;

  .home__top_avatar {
    border-radius: 50%;
  }

  .home__top_message {
    white-space: nowrap;
    left: 80%;
    top: -20px;
  }
}

@keyframes homeModel1 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(30px, -30px);
  }
  50% {
    transform: translate(30px, 30px);
  }
  75% {
    transform: translate(-30px, -30px);
  }
  100% {
    transform: translate(0, 0);

  }
}

.home__top_model_2 {
  position: absolute;
  right: 90px;
  top: -160px;
  width: 240px;
  height: 150px;
  animation: homeModel2 30s infinite alternate linear;

  .home__top_avatar {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transform: rotate(10deg);
  }

  .home__top_message {
    white-space: nowrap;
    left: 70%;
    top: 0;
  }
}

@keyframes homeModel2 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-30px, -30px);
  }
  50% {
    transform: translate(30px, -30px);
  }
  75% {
    transform: translate(30px, 30px);
  }
  100% {
    transform: translate(0, 0);

  }
}

.home__top_model_3 {
  position: absolute;
  left: 90px;
  bottom: -150px;
  width: 200px;
  height: 200px;
  animation: homeModel3 35s infinite alternate linear;

  .home__top_avatar {
    width: 100%;
    height: 100%;
    transform: rotate(10deg);
  }

  .home__top_message {
    left: 70%;
    bottom: 0;
    width: 230px;
    border-radius: 0 24px 24px 24px;
  }
}

@keyframes homeModel3 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(30px, 30px);
  }
  50% {
    transform: translate(-30px, 30px);
  }
  75% {
    transform: translate(30px, 30px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.home__top_message {
  position: absolute;
  background: rgba(255, 255, 255, 0.16);
  backdrop-filter: blur(20px);
  padding: 16px;
  border-radius: 24px 24px 24px 0;
  font-size: 14px;
  line-height: 20px;
  width: 200px;
  display: grid;
  overflow: hidden;
  color: #fff;

  span {
    white-space: pre-wrap;
  }
}

.home__section_program {
  margin-top: 60px !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-top: 100px;
    max-width: 460px;

    & + p {
      max-width: 430px;
      text-align: center;
      text-wrap: balance;
    }
  }

}

.home__section_program {
  background: url("../images/program_bg.svg") no-repeat center top /500px 420px;
}

.home__section_program-separator {
  max-width: 100%;
  height: 1px;
  width: 316px;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 40px;
  position: relative;

  &:before {
    pointer-events: none;
    content: '';
    position: absolute;
    width: 24px;
    height: 48px;
    bottom: -80px;
    left: -90px;
    background-color: var(--secondary-color);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    z-index: -1;
    filter: blur(20px);
  }

  &:after {
    pointer-events: none;
    content: '';
    position: absolute;
    width: 24px;
    height: 48px;
    bottom: -80px;
    right: -90px;
    background-color: var(--secondary-color);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    z-index: -1;
    filter: blur(20px);
  }
}

.home__program_btn {
  width: 140px;
  height: 140px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bg-main) !important;
  transition: var(--transition);
  cursor: pointer;
  position: absolute;
  top: 32px;
  z-index: 1;


  &:hover {
    background-color: #FFE176;
  }

  &:before {
    content: '';
    position: absolute;
    pointer-events: none;
    width: 196px;
    height: 144px;
    background-color: var(--secondary-color);
    border-radius: 50% 50% 25% 25%;
    z-index: -1;
    filter: blur(100px);
  }
}

.home__program_btn-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    width: 80px;
    height: 80px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    filter: blur(20px);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at center, transparent 30%, var(--secondary-color) 100%);
    border-radius: 50%;
    filter: blur(20px);
  }
}

.home__section_footer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.home__program_items {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.home__program_item-container {
  width: 50%;
  min-width: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  &:nth-child(1) {
    transform: rotate(6deg);
    position: absolute;
    bottom: 100%;
    left: -80px;
    margin-bottom: 16px;
  }

  &:nth-child(2) {
    transform: rotate(-6deg);
    position: absolute;
    bottom: 100%;
    right: -80px;
    margin-bottom: 16px;
  }

  &:nth-child(3) {
    margin-top: 16px;
    transform: rotate(3deg) translateX(0px);


  }

  &:nth-child(4) {
    margin-top: 16px;
    transform: rotate(-3deg) translateX(0px);
  }

  &:nth-child(5) {
    margin-top: 30px;
    transform: rotate(-3deg) translateX(120px);
  }

  &:nth-child(6) {
    margin-top: 30px;
    transform: rotate(3deg) translateX(-120px);
  }
}

@keyframes showProgram {
  from {
    transform: translateY(200px);
    opacity: 0;
  }
}

.home__program_item {
  position: relative;
  width: 300px;
  max-width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  padding: 16px;
  animation-name: showProgram;
  animation-fill-mode: both;
  animation-timing-function: linear;
  overflow: hidden;
  view-timeline-name: --show-in-program;
  animation-timeline: --show-in-program;
  animation-range: 20% 50%;


  img {
    width: 24px;
    height: 24px;
    margin-bottom: 8px;

  }

  p {
    text-align: left;
  }
}


.home__card_bg {
  position: relative;

  &:before {
    pointer-events: none;
    content: '';
    width: 100px;
    height: 100px;
    position: absolute;
    background-color: var(--tint-color);
    animation: circleBgAnim 20s infinite linear;
    margin-right: 50%;
    top: 100px;
    filter: blur(70px);
  }

  &:after {
    pointer-events: none;
    content: '';
    width: 100px;
    height: 100px;
    position: absolute;
    background-color: var(--secondary-color);
    animation: circleBgAnim 20s infinite linear;
    margin-left: 50%;
    bottom: 100px;
    filter: blur(70px);
  }
}

@keyframes circleBgAnim {
  0% {
    transform: translate(-50px, 0px);
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  25% {
    transform: translate(0, 80px);
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  50% {
    transform: translate(50px, -30px);
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  75% {
    transform: translate(20px, 80px);
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  100% {
    transform: translate(-50px, 0);
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.home__work_container {
  position: relative;
  margin-top: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.home__work {
  position: relative;
  z-index: 1;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  //backdrop-filter: blur(100px);
  padding: 40px 32px 32px;
  border-radius: 40px;
  display: flex;
  align-items: flex-start;
}


.home__work_step-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 4px;
  position: relative;
  margin-bottom: 8px;
  width: 100%;

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    background: var(--secondary-color);
    z-index: -1;
  }

}

.home__work_step {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bg-main);
  height: 32px;
  padding: 0 16px;
  background-color: var(--secondary-color);
  border-radius: 16px;
  position: relative;
  text-transform: lowercase;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: var(--secondary-color);
    border-radius: 16px;
    filter: blur(16px);
    z-index: -1;
    pointer-events: none;
  }

  &.primary {
    background-color: var(--tint-color);
    color: #fff;
    padding-left: 8px;

    &:before {
      background-color: var(--tint-color);
    }

    .react-icon {
      margin-right: 2px;
    }
  }
}


.home__work_item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:nth-child(1) .home__work_step-container:before {
    width: 50%;
    right: 0;
  }

  &:nth-child(3) .home__work_step-container:before {
    width: calc(50% - 24px);
    left: 0;
  }

  p {
    margin-top: 8px;
    max-width: 240px;
  }
}


.home__faq {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
}

.home__faq_item {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  transition: var(--transition);

  &:hover {
    border-color: #fff;
  }
}

.home__faq_item.active {
  background: rgba(255, 255, 255, 0.05);
}


.home__faq_item-head {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 24px;
  font-size: 24px;
  line-height: 24px;
}


.home__faq_item-content {
  transform: scale(0);
  opacity: 0;
  max-height: 0;
  font-size: 16px;
  line-height: 24px;
  color: var(--muted-color);
}

.home__faq_item.active .home__faq_item-content {
  transition: opacity .3s ease;
  transform: scale(1);
  opacity: 1;
  max-height: 200vh;
  display: block;
  margin-top: 0;
  padding: 4px 20px 20px 24px;
}

.home__join_form {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 40px;
  display: flex;
  align-items: flex-start;
  padding: 24px;
  margin: 40px auto 0;
  max-width: 100%;
  width: 550px;
  overflow: hidden;

  form {
    width: 100%;
  }
}


.home__program_slider {
  display: none;
  width: 100vw;

  .slick-slide > div {
    height: 100%;
  }

  .home__program_item-container {
    min-width: auto;
    width: auto;
    padding: 0 8px;

  }

  .home__program_item {
    height: 100%;

    &:before {
      display: none !important;
    }
  }
}

.home__section_tools {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home__tools_wrap {
  margin-top: 40px;
  width: 100%;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    left: 120px;
    width: 30px;
    background-color: var(--secondary-color);
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    margin-left: 40%;
    width: 30px;
    background-color: var(--tint-color);
    left: 40px;
    z-index: -1;
  }
}

.home__tools_bg {
  position: absolute;
  inset: 0;
  border-radius: 60px;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    right: 20px;
    width: 30px;
    background-color: var(--secondary-color);
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    margin-left: 40%;
    width: 30px;
    background-color: var(--tint-color);
    right: 280px;
    z-index: -1;
  }
}

.home__tools_bg {
  position: absolute;
  inset: 0;
}

.home__tools {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(100px);
  padding: 16px;
  border-radius: 40px;
  display: flex;
  flex: 1;
  align-items: stretch;

}

.home__tools_item {
  position: relative;
  z-index: 1;
  display: flex;
  padding: 32px;

  p {
    text-align: left;
  }

}

.home__tools_item-idx {
  color: var(--secondary-color);
  border-right: 1px solid var(--secondary-color);
  padding-right: 8px;
  margin-right: 16px;
  width: 18px;
  min-width: 18px;
}

.home__tools_phone {
  position: relative;
  flex: 1;
  width: 40%;
  min-width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 40px;
    background-color: var(--bg-main);
    bottom: -56px;
  }

  img {
    position: absolute;
    bottom: -40px;
    width: 200px;
    transform: rotate(8deg);
  }
}

.home__tools_circle {
  width: 106px;
  height: 106px;
  position: absolute;
  border-radius: 50%;
  //animation: circleBgAnim 20s infinite linear;
  border: 1px solid var(--tint-color);
  left: 60px;
  bottom: 32px;

  &:before {
    pointer-events: none;
    content: '';
    inset: -1px;
    position: absolute;
    border: 2px solid var(--tint-color);
    filter: blur(2px);
    border-radius: 50%;
  }
}

.home__tools_circle2 {
  width: 140px;
  height: 140px;
  position: absolute;
  border-radius: 50%;
  //animation: circleBgAnim 20s infinite linear;
  border: 1px solid var(--secondary-color);
  right: 40px;
  top: 12px;

  &:before {
    pointer-events: none;
    content: '';
    inset: -1px;
    position: absolute;
    border: 2px solid var(--secondary-color);
    filter: blur(2px);
    border-radius: 50%;
  }
}


.home__stat_list {
  margin-top: 24px;

  p {
    text-align: left;
  }

  .col-12:last-child {
    h4 {
      font-size: 16px;

      span {
        font-size: 24px;
      }
    }
  }
}

.home__stat_item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 176px;
  border-radius: 24px;
  border: 1px solid var(--border-color);
  padding: 20px 24px;
  justify-content: space-between;
}