@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  .form-control {
    &::placeholder {
      font-size: 14px !important;
    }
  }
  .form-group:has(.input-icon) .form-control {
    padding-left: 52px;
  }
  .form-control-wrap {
    border-radius: 20px;
  }
  .form-control-icon {
    padding-left: 56px;
  }
  .input-icon {
    left: 20px;
    width: 24px;
    height: 24px;
  }
  .form-control-wrap:has(select):after {
    right: 24px;
  }
  select.form-control {
    padding-right: 48px;
  }
  .form-group:has(select) {
    height: 40px;
    .form-control-wrap:after {
      right: 12px;
    }

    .form-control {
      padding-left: 44px!important;
      min-height: 40px;
    }
    .input-icon {
      left: 12px;
    }
  }

}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {


}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}