@media only screen and (max-width: 1199px) {
  .home__top_model_2 {
    top: -150px;
  }
  .home__top_model_3 {
    bottom: -50px;
    left: 25px
  }
  .home__program_item-container {
    &:nth-child(1) {
      transform: rotate(6deg);
      left: -120px;

    }
    &:nth-child(2) {
      transform: rotate(-6deg);
      right: -120px;
    }
    &:nth-child(3) {
      transform: rotate(3deg) translateX(3px);
    }

    &:nth-child(4) {
      transform: rotate(-3deg) translateX(-3px);

    }

    &:nth-child(5) {
      transform: rotate(-3deg) translateX(72px);
    }

    &:nth-child(6) {
      transform: rotate(3deg) translateX(-72px);
    }
  }
  .home__tools_item {
    padding: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .home__page {
    section {
      margin-top: 32px;
    }

    h2 {
      margin-bottom: 16px;
    }
  }
  .home__top_model_3 {
    position: relative;
    bottom: 0;
    left: 0;
    margin-bottom: 54px;
    margin-right: 200px;
  }
  .home__top_model_2 {
    display: none;
  }
  .home__top_model_1 {
    left: auto;
    top: 0;
    margin-left: 200px;

    .home__top_message {
      display: none;
    }
  }
  .home__page h1 {
    margin-bottom: 16px;
  }
  .home__top_bg {
    width: 355px;
    height: 325px;
  }
  .home__section_top {
    padding-top: 0;
    justify-content: flex-start;
    margin-top: 124px !important;
    height: auto;
    min-height: auto;
  }
  .home__section_top .btn {
    margin-top: 32px;
    min-height: 64px;
    border-radius: 20px;
  }
  .home__section_program {
    margin-top: 36px !important;
    background: rgba(255, 255, 255, 0.1);
    margin-left: -24px;
    margin-right: -24px;
    padding-top: 24px;
    border-radius: 40px;
    padding-bottom: 24px;

    h2 {
      margin: 0 0 16px;
      padding: 0 32px;

      & + p {
        padding: 0 32px 24px;
      }
    }
  }
  .home__program_btn, .home__program_btn-bg, .home__section_program-separator {
    display: none;
  }
  .home__program_items {
    display: none;
  }
  .home__program_slider {
    display: block;
  }
  .home__program_item-container {
    transform: none !important;
    position: relative!important;
    left: auto!important;
    right: auto!important;
    margin-bottom: 0!important;
    bottom: auto!important;
  }
  .home__program_item {
    animation: none;
  }
  .home__work_container {
    margin-top: 32px;
    overflow: visible;

    &:before, &:after {
      display: none;
    }
  }
  .home__work {
    padding: 0;
    flex-direction: column;
    background: none;
    align-items: center;
    gap: 40px;
  }
  .home__work_step-container {
    gap: 0;

    &:before {
      display: none;
    }
  }
  .home__work_item {
    padding-bottom: 16px;
    width: 100%;
    background: rgba(255, 222, 103, 0.1);
    border: 1px solid rgba(255, 222, 103, 1);
    border-radius: 24px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 100%;
      width: 1px;
      height: 40px;
      background: var(--secondary-color);
    }

    &:last-child {
      &:before {
        display: none;
      }

      .home__work_step {
        min-width: 90px;
        border-radius: 16px 0 0 16px;

        &.primary {
          border-radius: 0 16px 16px 0;
        }
      }
    }

    .text-accent {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .home__work_step {
    margin-top: -16px;
  }
  .home__faq_item-head {
    font-size: 16px;
    line-height: 24px;
  }
  .home__faq_item-content {
    font-size: 14px;
    line-height: 20px;
    padding-top: 0 !important;
  }
  .home__faq_item.active .home__faq_item-content {
    margin-top: -12px;
  }
  .home__section_join {
    margin: 0 -24px;
  }
  .home__join_form {
    margin: 0 auto;
  }
  .home__section_tools {
    margin-bottom: 80px;
  }
  .home__tools_wrap {
    margin-top: 0;

    &:before, &:after {
      display: none;
    }
  }
  .home__tools_bg, .home__tools_circle, .home__tools_circle2 {
    display: none;
  }
  .home__tools {
    margin: 16px auto 0;
    max-width: 500px;
    padding: 0;
    background: none;
  }
  .home__tools_item {
    padding: 8px 0;
  }
  .home__tools_phone {
    width: 140px;
    min-width: 140px;

    &:after {
      display: none;
    }

    img {
      left: 44px;
      right: auto;
      bottom: auto;
      width: 140px;
      top: 0px;
    }
  }
  .home__stat_list {
    max-width: 500px;
    margin:  0 auto;
  }
  .home__stat_list .col-12 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .home__tools_item {
    font-size: 12px;
    line-height: 18px;

    p {
      font-size: 12px;
      line-height: 18px;
    }

    .home__tools_item-idx {
      margin-right: 8px;
      min-width: 13px;
      width: 13px;
      text-align: right;
      padding-right: 4px;
    }
  }
  .home__stat_item {
    height: auto;
    h4 span{
      font-size: 16px!important;
      font-weight: 700;
    }
  }

}

@media only screen and (max-width: 575px) {
  .home__stat_item {
    padding: 16px;
  }
  .home__tools_phone {
    z-index: -11;
    min-width: 60px;

    img {
      left: -20px;

    }
  }
  .home__tools {
    &:after {
      content: '';
      position: absolute;
      inset: -10px;
      background: #070513;
      border-radius: 30%;
      filter: blur(80px)
    }
  }
  .home__section_top .btn {
    width: 100%;
    border-radius: 20px;

    .btn__icon {
      position: absolute;
      left: 24px;
      margin: 0;
    }
  }
  .auth__page_footer-btns {
    flex-direction: column;

    .btn {
      min-height: 64px;
    }

  }
  .home__top_bg {
    .light {
      display: none;
    }
  }
  .home__program_slider .home__program_item-container {
    padding: 0 4px;
  }
}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}