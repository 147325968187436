footer {
  padding: 120px 24px 48px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    background: rgba(255, 255, 255, 0.16);
    left: -80px;
    bottom: -80px;
    right: -80px;
    height: 290px;
    filter: blur(60px);
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    background: rgba(7, 5, 19, 0.6);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    filter: blur(60px);
    z-index: -1;
  }
}
.home__footer {
  max-width: 100%;
  width: 1328px;
  margin: 0 auto;
  padding: 24px;
  align-items: center;
  display: flex;
  background-color: var(--bg-main);
  justify-content: space-between;
  border-radius: 16px;
}
.home__footer-right {
  display: flex;
  gap: 24px;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  footer {
    padding-top: 32px;
  }
}


@media only screen and (max-width: 767px) {
  .home__footer {
    flex-direction: column;
    gap: 24px;
  }
  .home__footer-right {
    gap: 16px;
    flex-direction: column;
  }
}

@media only screen and (max-width: 575px) {
  footer {
    padding: 32px 24px;
    &:before, &:after {
      display: none;
    }
  }
  .home__footer {

    padding: 0;
    flex-direction: column;
  }
  .home__footer-right {
    gap: 16px;
    align-items: center;
    flex-direction: column;
  }
}