@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  .btn {
    min-height: 40px;
    border-radius: 16px;
  }

}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {


}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}

