
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;

}

::-webkit-scrollbar-thumb {
  background: var(--bg-secondary);
  border-radius: 4px;
  margin-left: 4px;
}

* {
  outline: none;
}

html {
  scroll-behavior: smooth;
  //overflow-x: hidden;
  overflow-x: hidden;
}
.pre-wrap {
  white-space: pre-wrap;
}
.text-balance {
  text-wrap: balance;
}

body {
  //font-size: clamp(14px, 2vw, 16px);
  //line-height: clamp(20px, 2vw, 24px);
  overflow: hidden;
  font-family: var(--font-regular);
  font-size: 16px;
  line-height: 24px;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-main);
  color: var(--text-color);
  cursor: default;

}


h1, h2, h3, h4, h5, h6, p {
  margin-bottom: 0;
}

p {
  font-size: 16px;
  line-height: 24px;
  user-select: auto;
}

h1 {
  position: relative;
  font-weight: 500;
  text-wrap: balance;
  font-size: 64px;
  line-height: 64px;
}

h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
}

h3 {
  font-family: var(--font-regular);
  font-size: 24px;
  line-height: 24px;
}

h4 {
  font-family: var(--font-regular);
  font-size: 24px;
  line-height: 36px;
}



#root, main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}
nav {
  user-select: none;
}

section {
  position: relative;
}

.flex-1 {
  flex: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-move {
  cursor: move;
}

.cursor-help {
  cursor: help;
}

.text-muted {
  color: var(--muted-color) !important;
}

.text-muted-14 {
  color: var(--muted-color);
  font-size: 14px;
  line-height: 20px;
}

.text-danger {
  color: var(--danger) !important;
}

.text-danger-14 {
  color: var(--danger);
  font-size: 14px;
  line-height: 20px;
}

.text-24 {
  font-size: 24px;
  line-height: 36px;
}

.text-regular {
  font-weight: 400;
}

.text-medium {
  font-weight: 500;
}

.text-medium-14 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.text-medium-12 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}


.text-14 {
  font-size: 14px;
  line-height: 20px;
}

.text-12 {
  font-size: 12px;
  line-height: 18px;
}

a, .link, .text-active {
  cursor: pointer;
  color: #fff;
  transition: var(--transition);
  text-decoration: none;

  &:hover {
    color: var(--tint-color);
  }
}

.text-accent {
  color: var(--tint-color);
}

.text-tint {
  color: var(--secondary-color);
}


.react-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.Toastify__toast {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 16px;
  background: var(--bg-main);
  padding: 16px;
  font-family: var(--font-regular);

  .react-icon svg {
    width: 20px !important;
    height: 20px !important;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    background: rgba(255, 255, 255, 0.1);
  }
}

.Toastify__progress-bar {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  height: 4px;
  background: var(--gradient);
}

.Toastify__toast--error {
  background: #562131;
}


.slick-dots {
  position: relative;
  bottom: 0;
  margin-top: 24px;
  height: 8px;
  display: flex!important;
  justify-content: center;
  align-items: center;
}

.slick-dots li {
  width: auto;
  height: auto;
  margin: 0 4px;
}

.slick-dots li button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

.slick-dots li button:before {
  content: '';
  width: 8px;
  height: 8px;
  background: rgba(255, 255, 255, 0.16);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  background-color: #fff;
}
.slider-container { display: inline-block; width:500px }
//
//.slick-slide {
//  display: flex;
//  flex: 1;
//  justify-content: center;
//  align-items: center;
//  height: 100%;
//}
//
//.slick-slide > div {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  flex: 1;
//  height: 100%;
//}

.text-truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.change__lang_select {
  width: 224px;
  max-width: 100%;
}